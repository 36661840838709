button{
    color: white;
    background-color: #2a66f2fc;
}

.closebtnpopup{
color:  #0A65CC;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
text-transform: capitalize;
display: flex;
padding: 9px 24px;
justify-content: center;
align-items: center;
gap: 12px;
border-radius: 3px;
background:  #E7F0FA;
border: none;
}
.copylink{
    /* display: grid; */
    /* grid-template-columns: repeat(1, 1fr); */
    /* flex-direction: column; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.textclose{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}