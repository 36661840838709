/* Resumepool.css */

  
  input[type="file"] {
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-top: 20px;
    width: 100%;
    background-color: #ffffff;
  }
  
  h4 {
    margin-top: 20px;
    color: #333;
  }
  

  
  .alert-success {
    margin-top: 20px;
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    padding: 10px;
    border-radius: 4px;
  }
  