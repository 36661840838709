.wave-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  .container{
    margin-bottom: 40px;
  }
  .bar {
    display: inline-block;
    width: 10px;
    height: 30px;
    margin-right: 2px;
    background-color: #000;
    animation: wave-animation 1s infinite ease-in-out alternate;
  }
  .audio-container {
    display: flex;
    align-items: center;
  }
  
  .audio-element {
    width: 100%;
  }
  
  .delete-icon {
    margin-left: 350px;
    cursor: pointer;
    color: red;
    margin-top: -90px;
  }
  .file-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 6px;
  }
  .red-border {
    border: 2px solid red;
  }
  .record-text{
    font-size: 26px;
    font-weight: 400;
    color: black;
  }
  
  @keyframes wave-animation {
    0% {
      height: 10px;
    }
    50% {
      height: 30px;
    }
    100% {
      height: 10px;
    }
  }

  .custom-card-body {
    width: 50%;
    margin: 0 auto;
  }
  
  @media (max-width: 575.98px) {
    .custom-card-body {
      width: 100%;
    }
  }



  /* new  */
/* .btns{
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

} */

  .btn-primary{
    width: 13%;
    padding: 11px;
    cursor: pointer;
    /* gap: 10px; */
    border-radius: 7px;
    background: var(--primary-100, #CEE0F5);
  }

  .btn-primary-stop{
    width: 10%;
    padding: 7px;
    cursor: pointer;
    /* gap: 10px; */
    border-radius: 100px;
    background: var(--primary-100, #d5d5d5);
  }

  .start_rec{
    margin-right: 70px;
    background: none;
    border: none;
  }
  .stop_rec{
    margin-right: 10px;
    background: none;
    border: none;
  }
  .rec-submmit{
   
 
  }

  /* Add these styles to your CSS file */

/* .recording {
  animation: pulse 1s infinite alternate;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.recording-indicator {
  margin-top: 10px;
  text-align: center;
  font-weight: bold;
  color: red;
} */
