.logo{
  display: flex;
  align-items: center;
}
.logo-name{
  font-size: 26px;
  font-weight: 600;
  color: black;
  /* border: 1px solid red; */
}
.postjobbtn{
  background-color: #0A65CC;
  color: #F8F9FA;
  font-weight: 600;
  padding: 12px 24px 13px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-family: sans-serif;
}
@media (max-width: 768px) {
  .navBar .hide-on-mobile {
    display: none !important;
  }
}

.beta{
  
  font-size: 12px;
  color:#5E6670!important;
  border: 1px solid gray;
  margin: 7px 0px 0px 10px;
  border-radius: 5px;
  padding: 0px 3px;
}

.modal-footer {
  border-top: none !important;
}


.location-suggestions-overlay {
  position: absolute;
  z-index: 1000;
  background: white;
  border: 1px solid #ccc;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}

.location-suggestions-overlay ul {
  list-style: none;
  padding: 0;
  margin: 0px;
}

.location-suggestions-overlay li {
  padding: 4px;
  cursor: pointer;
}

.location-suggestions-overlay li:hover {
  background-color: #f0f0f0;
}

/* Navbar.css */

.notification-icon {
  position: relative;
  cursor: pointer;
}

.notification-dropdown {
  position: absolute;
  top: 50px;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.notification-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-item:hover {
  background: #f9f9f9;
}
