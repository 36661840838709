/* Add this CSS to your stylesheet */
.job-select-container {
    width: 50%;
    position: relative;
   
  }
  
/* CSS */
.job-select {
  padding:5px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 4px;
  border: 2px solid rgba(228, 229, 232, 1);
  width: 260px;
  /* margin-bottom: 1px;  */
  /* margin-left: 8px; */
  /* border-bottom: 1px solid rgba(228, 229, 232, 1);  */
  overflow-y: auto; 
}

.job-option{
  padding: 5px;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  border-bottom: 1px solid rgba(228, 229, 232, 1); 
}

.job-select::-webkit-scrollbar {
  display: none;
}

/* Modal container */
.modaljd {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  
}

.upload-button:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}


.modal-contentjd {

  position: relative;
  background-color: #fefefe;
  padding: 20px;
  border-radius: 10px;
  max-width: 40%;
  max-height: 80%;
  overflow-y: auto; 
}

.modal-contentjd::-webkit-scrollbar {
  width: 0; 
  background: transparent;
}

.modal-contentjd::-webkit-scrollbar-thumb {
  background-color: transparent; 
}


.job-details-container {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  font-family: Arial, sans-serif;
}

.job-details-container h2 {
  margin-bottom: 10px;
}

.job-details-container p {
  margin: 0 0 10px 0;
  line-height: 1.5;
}

.job-details-container ul,
.job-details-container ol {
  margin: 0 0 10px 20px;
}

.job-details-container li {
  margin-bottom: 5px;
}

.close{
  font-size: 30px;
  margin-right: -7px;
  margin-top: -13px;
}

/* Modal container clsoe */

.top-select-upload{
  display: flex;
}

.overBox{
  margin-left: -40px;
}

/* Add these styles to your existing SearchResumes.css file */

/* Search Results */
.search-results {
  margin-top: 20px;
}

/* Search Result Card */
.search-result-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

/* Score */
.score {
  display: flex;
  gap:28px;
  font-weight: 300;
}

.summary {
  margin-top: 5px;
}

/* Download and View Resume Buttons */
.action-buttons {
  margin-top: 10px;
}

.action-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.download-button {
  background-color: #28a745;
}

/* Minimize Button */
.minimize-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.copy-button-container {
  position: absolute;
  top: 40px;
  right: 50px;
  display: flex;
  align-items: center;
}

.copy-button{
  /* border: 1px  solid red; */
  width: 120px;
  height: 50px;
}

.progress-bar-container {
  /* width: 50%;  */
}

/* Add this CSS to your stylesheet */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 999; /* Ensure it's above other content */
}

.resume-viewer-container {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0;
  transform: translate(-50%, -50%);
  max-width: 100%; /* Ensure it doesn't exceed the viewport width */
  max-height: 100%; /* Ensure it doesn't exceed the viewport height */
  overflow: auto;
  background-color: white;
  padding: 20px;
  /* border-radius: 5px; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  z-index: 1000; /* Ensure it's above the overlay */
}



.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  color: #333;
  cursor: pointer;
}



@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* .loading-spinner::after {
  content: "";
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-top: 2px solid #007bff; 
  border-radius: 50%;
  display: inline-block;
  animation: spin 1s linear infinite; 
  margin-left: 10px; 
} */

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  font-size: 14px; /* Adjust the font size as needed */
  color: #007bff; /* Adjust the color to match your design */
}

/* Add to your SearchResumes.css */
/* Add this to SearchResumes.css */

.card-details {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  opacity: 0;
}

.card-details-expanded {
  max-height: 1000px; 
  overflow-y: auto;
  opacity: 1;
}

.card-details-expanded::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just in case you don't want a background */
}



/* CSS for the smaller toggle switch */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  vertical-align: middle;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.upload-button {
  display: flex;
  align-items: center;
  /* margin-left: 10px;
  padding: 8px 13px;
  border-radius: 4px;
  background-color: white;
  color: rgba(10, 101, 204, 1);
  border: 2px solid rgba(10, 101, 204, 1);
  cursor: pointer; */
}

.upload-button {
  position: relative;
  display: inline-block;
}

.upload-button .tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 120%; /* Position the tooltip above the button */
  left: 50%;
  margin-left: -100px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.upload-button:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
