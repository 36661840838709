/* ErrorPage.css */
.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  .error-container h1 {
    color: #f00;
  }
  
  .error-container p {
    color: #777;
  }
  