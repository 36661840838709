/* AdminStatus.css */

.table {
    width: 100%;
    margin: 20px 0;
    border-collapse: collapse;
  }
  
  .table th, .table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .table th {
    background-color: #f2f2f2;
  }
  
  .table tr:hover {
    background-color: #f1f1f1;
  }
  
  .action-button {
    width: 80%;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 4px;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    color: white;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
    border-color: #004085;
  }
  
  .container.second-main {
    margin-top: 20px;
  }
  