/* ChatModal.css */
.chat-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .chat-modal-content {
    background: white;
    border-radius: 5px;
    padding: 20px;
    width: 400px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px; /* Adjust size as needed */
  }
  
  .chat-header {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .chat-messages {
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
  }
  
  .chat-message {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 5px;
  }
  
  .chat-message.you {
    background-color: #e1ffc7;
    align-self: flex-start;
  }
  
  .chat-message.other {
    background-color: #f1f1f1;
    align-self: flex-end;
  }
  
  .chat-input-container {
    display: flex;
  }
  
  .chat-input-container input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .chat-input-container button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  