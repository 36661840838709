.rt-pt-30 {
  padding-top: 30px;
}

/* Add this CSS to your component's stylesheet */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  
}

.pagination button {
  height: 40px;
  width: 40px;
  background-color: transparent;
  color: gray;
  border: none;
  border-radius: 50%;
  font-size: 16px;
  margin: 0 5px;
  cursor: pointer;
}

.pagination button.active {
  background-color: #0A65CC;
  color: white;
}
.pageButtons{
  margin: 10px;
}

.pagination button:hover {
  background-color: rgb(229, 229, 229);
  color:gray;
}

.hover-effect:hover {
  stroke: #0A65CC !important;
  cursor: pointer;
}

.header-dropdown {
  display: flex;
  align-items: center;
}
.header-dropdown h4 {
width: 80px;
line-height: 40px;
}

.text-gray-900 {
color: #18191c!important;
}
.f-size-14 {
font-size: 14px!important;
}

.select2-hidden-accessible {
padding: 8px 50px 8px 15px !important;
border-radius: 5px;
border:1px solid #c8c9cb;
}