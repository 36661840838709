#parentM li a span {
  color: gray;
  font-size: 17px;
}

.active {
  background-color: #EEF5FC;
  color: #2F7CD3;
  border-left: 3px solid #0A65CC;
  
}


#parentM li.active a span {
  color: #2F7CD3;
}

#parentM li a {
  padding: 7px 20px;
  display: block;
  line-height: 24px;
  font-weight: 500;
  transition: background-color 0.3s, color 0.3s; /* Add a smooth transition effect */
}

#parentM li:hover {
  background-color: #F1F2F4; 
  color: black;
}

#parentM li:hover a span {
  color: black; /* Change text color of child elements on hover */
}

#parentM li:hover a img {
  stroke: black; /* Change text color of child elements on hover */
}

.container-fluid{
  /* border-right:1px solid rgb(222, 220, 220); */
  margin: 0;
  /* padding-top: 30px; */
 
}



/* #parentM li::after{
  border-right: 1px solid red;
} */

/* Sidebar Styles */
.job-sidebar {
  width: 250px; /* Default width of the sidebar */
  transition: width 0.3s;
}

/* Add any additional styling you need here */

/* Mobile Styles */
@media (max-width: 767px) {
  .job-sidebar {
    display: none; 
  }

  
  .d-sm-none {
    display: block;
  }
  /* Add this CSS to your Sidebar.css file or a separate CSS file */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1050; /* Make sure it's above Bootstrap's modal backdrop */
}

}

/* .job-sidebar{
 
  border-right: 1px solid #ccc;
} */

/* #parentM{
  border: 2px solid red;
} */