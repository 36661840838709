

.topname{
  display: flex;
  align-items: center;
justify-content: space-between;

}
.breadcrumbJob {
  color: #0066FF;
  /* padding: 20px 20px 20px 100px; */
  margin-right: 300px;
  /* margin-bottom: 15px; */
  transition: all 0.24s ease-in-out;
  /* background-color: #F1F2F4; */
  
}
.jdtop{
  /* border: 1px solid red; */
  /* padding-bottom: 20px; */
  /* margin-bottom: -150px; */
}

.jobDescription {

  height: 60px;
  background-color: #F1F2F4;
}

/* SingleJobPost.css */
/* SingleJobPost.css */
.logo-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px; /* Adjust as needed */
}

.posted-by {
  display: flex;
  flex-direction: column;
  margin-left: -8px;
  align-items: flex-start;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: #333; 
}

.logo-image {
  max-width: 100%;
  height: auto;
  object-fit: contain; /* This ensures the image scales correctly */
  max-height: 70px; /* Adjust this value as needed to control max height */
}




.topJobType{
padding: 3px 12px;
gap: 10px;
border-radius: 3px;
background: #E8F1FF;
color: #0066FF;
 margin-left: 10px;
}
.topnameBelow{
  /* margin-bottom: -50px; */
  display: flex;
  margin-left: 2px;
  margin-top: -30px;
  margin-bottom: -25px;
  /* flex-direction: column; */
}
.afterInstaBox{
  margin-left: -230px;
}

.topPosition{
  margin-left: 20px;
  color: var(--gray-900, #18191C);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; 
}
.topright{
  display: flex;
  justify-content: center;
  align-items: center;
padding: 0px 62px;
padding-top: 12px;
border-radius: 4px;
color: #FFFFFF;
background: #0A65CC;
cursor: pointer;
}
.toprighttwo {
  display: flex;
  justify-content: center;
  align-items: center;
padding: 0px 62px;
padding-top: 12px;
border-radius: 4px;
  color: #18191C;
  font-weight: 500;
  background: #F1F2F4;
  cursor: not-allowed;
}
.maininfo{
  margin-top: -20px;
  padding-top: 0px !important;
  display: flex;
  padding-top: -10px; /* Negative padding value */
  border-top: 1px solid #eaeaea; 
  
}

/* .maininfo div{
  margin-top: 8px;
} */

.leftbox{
  padding-top: -0px;
  margin-top: -100px;
}

.rightbox{
  margin-top: -20px;
}
.nameType{
  margin-top: 10px;
}
.locstate{
  margin-left: 20px;
}
.tw-pt-8 {
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 20px;
}
.single-jSidebarWidget {
  display: flex;
  margin-bottom: 20px; 

}

.checktop{
  margin-top: -50px;
}
.tw-pt-6 {
  padding-top: 1.5rem;
}

.tw-pb-8 {
  padding-bottom: 2rem;
}
.tw-px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.tw-text-\[\#18191C\] {
  --tw-text-opacity: 1;
  color: rgb(24 25 28 / var(--tw-text-opacity));
}
.tw-font-medium {
  font-weight: 500;
}
.tw-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.tw-mb-2 {
  margin-bottom: .5rem;
}
.tw-p-0 {
  padding: 0;
}

.tw-gap-2 {
  gap: .5rem;
}
.tw-items-center {
  align-items: center;
}
.tw-list-none {
  list-style-type: none;
}

.tw-flex {
  display: flex;
}
.tw-mb-6 {
  margin-bottom: 1.5rem;
}
.tw-m-0 {
  margin: 0;
}
.tw-text-\[\#0A65CC\] {
  --tw-text-opacity: 1;
  color: rgb(10 101 204 / var(--tw-text-opacity));
}

.tw-font-medium {
  font-weight: 500;
}
.tw-text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.tw-py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.tw-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.tw-bg-\[\#E7F0FA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(231 240 250 / var(--tw-bg-opacity));
}

.tw-rounded-\[4px\] {
  border-radius: 4px;
}
.tw-gap-1\.5 {
  gap: .375rem;
}
.tw-items-center {
  align-items: center;
}

.tw-cursor-pointer {
  cursor: pointer;
}
.tw-flex {
  display: flex;
}

.tw-share-area {
  border-top: 2px solid #E7F0FA;
}

.single-jSidebarWidget .iconbox-content {
  
  margin-top: 10px; 
}
.tw-pb-6 {
  padding-bottom: 1.5rem;
}
.tw-px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.belowJob{
  width: 480px;

}
.job-description {
  text-align: justify;
}

.socioLinks span{
 margin-right: 15px;
}
.companyDet{
  display: flex;
  justify-content: space-between;
  color: #18191C;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}
.card {
  border: none; 
}

.copyFooter {
  background: #ffffff;
  box-shadow: inset 0 1px #edeff5;
  padding: 20px 0;
}
@media (max-width: 598px) {
  /* Change the layout for small screens */
  .maininfo {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
  }
  .topname {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .topright {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border-radius: 4px;
    color: #FFFFFF;
    background: #0A65CC;
    cursor: pointer;
  }
  .toprighttwo {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border-radius: 4px;
    color: #18191C;
    background: #282899;
    cursor: pointer;
  }
  .infoRight{
    /* display: flex;
    flex-direction: column; */
    border: 1px solid red;
  }
  
  .col-xl-4 .rt-mb-32 {
    width: 25%; /* Adjusted to 25% */
    margin-bottom: 20px; /* Adding margin-bottom for spacing between the divs */
  }
  
  
  .companyDet {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    color: #18191C;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    
  }
  .card {
    border: none; /* Add this line to remove the border */
  }
  
}

