@media (min-width: 1200px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1140px;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
}
  }

 .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    width: 100%;
    /* padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem); */
    margin-right: auto;
    margin-left: auto;
  }

  .dashboard-right-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .left-text {
    margin-bottom: 24px;
  }

  .left-text h5 {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 8px;
    color: #18191c;
  }

  left-text p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #18191c;
  }

  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5);
  }

  /* .row:nth-child(1) {
    flex-shrink: 0;
    width: 30%;
    max-width: 30%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
  } */

  .single-feature-box {
    padding: 20px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    margin-bottom: 24px;
    justify-content: space-between;
    background-color: #eef5fc;
  }

  .single-feature-data h6 {
    font-weight: 600;
    margin-bottom: 4px;
  }

  /* check */
  .single-feature-data p {  
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #18191c;
    opacity: .8;
    margin: 0;
  }

  .single-feature-icon {
    width: 64px;
    height: 64px;
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }

  .col-xl-5.col-lg-6.col-md-6:nth-child(1) .single-feature-icon i {
    color: #0A65CC;
  }
  .col-xl-5.col-lg-6.col-md-6:nth-child(2) .single-feature-box {
    background-color: #FFF7E6;
  }

  .single-feature-data h6 {
    font-weight: 600;
    margin-bottom: 4px;
  }

  .tw-text-\[\#18191C\] {
    --tw-text-opacity: 1;
    color: rgb(24 25 28 / var(--tw-text-opacity));
  }

  .tw-text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .rt-mb-15 {
    margin-bottom: 15px;
  }

  .align-items-center {
    align-items: center !important;
  }

  .justify-content-between {
    justify-content: space-between!important;
  }

  .d-flex {
    display: flex!important;
    text-decoration: none;
  }

  .f-size-16 {
    font-size: 18px!important;
/* font-weight: 600; */

  }
  .jobsCount{
    color: gray;
  }

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: Inter,sans-serif;
    color: #18191C;
    font-weight: 500;
  }
  
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
  }

  .text-gray-500 {
    color: #767f8c!important;
  }
  
  .f-size-16 {
    font-size: 16px!important;
  }

  .db-job-card-table table {
    width: 100%;
  }

  table {
    caption-side: bottom;
    border-collapse: collapse;
    text-indent: initial;
    border-spacing: 2px;
  }

  tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
  }
  thead {
    display: table-header-group;
    vertical-align: middle;
  }

  .db-job-card-table table thead tr th:first-child {
    border-radius: 4px 0 0 4px;
  }

  .db-job-card-table table thead tr th {
    font-weight: 400;
    font-size: 12px;
    padding: 10px 20px;
    white-space: nowrap;
    color: #474C54;
    background-color:#F1F2F4;
  }

  .db-job-card-table table thead tr th:last-child {
    padding-left: 52px;
    border-radius: 0 4px 4px 0;
  }

  tr {
    display: table-row;
    vertical-align: inherit;
   
  }

  .db-job-card-table table tbody tr {
    transition: all .4s;
    border: 1px solid transparent;
    box-shadow: 0 1px 0 0 #E4E5E8;
  }

  .db-job-card-table table tbody tr td:first-child {
    border-radius: 8px 0 0 8px;
  }

  .db-job-card-table table tbody tr td {
    padding: 20px;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    color: #5E6670;
    text-decoration: none;
  }
  div {
    display: block;
  }

  .text-gray-600 {
    color: #5e6670!important;
  }

  .body-font-4 {
    font-size: 14px;
    line-height: 22px;
  }

  .pt-2 {
    padding-top: .5rem!important;
  }

  .text-gray-900 {
    color: #18191c!important;
  }

  .f-size-16 {
    text-decoration: none;
    font-size: 16px!important;
  }

  .ft-wt-5 {
    font-weight: 500!important;
  }

  a{
    text-decoration: none;
    transition: all .24s ease-in-out;
  }

  .rt-mr-8 {
    margin-right: 8px!important;
  }
  
  .text-success-500 {
    color: #0ba02c!important;
  }

  .ft-wt-5 {
    font-weight: 500!important;
  }
  .align-items-center {
    align-items: center!important;
  }
  .d-flex {
    display: flex!important;
  }

  .rt-mr-4 {
    margin-right: 4px!important;
  }
  
  .f-size-18 {
    font-size: 18px!important;
  }
  [class^=ph-], [class*=" ph-"] {
    display: inline-flex;
  }

  .f-size-20 {
    font-size: 20px!important;
  }
  
  .mt-1 {
    margin-top: .25rem!important;
  }

  .align-items-center {
    align-items: center!important;
  }
  .d-flex {
    display: flex!important;
  }

  .db-job-card-table table tbody tr td:last-child {
    border-radius: 0 8px 8px 0;
  }
  
  .justify-content-end {
    justify-content: flex-end!important;
  }
  .d-flex {
    display: flex!important;
  }

  .db-job-card-table table tbody tr:hover .btn {
    color: #fff!important;
    background-color: #0A65CC !important;
  }
  .db-job-card-table table tbody tr .btn:nth-child(1) {
    background-color: #F1F2F4;
    color: #0A65CC;
    padding: 12px 24px 13px;
    font-family: Inter,sans-serif;
  }

  .db-job-card-table table tbody tr td:nth-child(3) {
    display: flex;
    justify-content: center;
  }

  .btn:focus,
  .btn:active {
    outline: none; /* Remove the outline/border */
    box-shadow: none; /* Remove any box-shadow */
  }

  .button-text{
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  .rt-mr-8 {
    margin-right: 8px!important;
  }

   
  .db-job-card-table table tbody tr:hover .btn-icon {
    background-color: #F1F2F4!important;
  }
  .db-job-card-table table tbody tr:hover {
    border-radius: 8px!important;
  }
  .db-job-card-table table tbody tr:hover {
    box-shadow: 0 0 0 1px #0A65CC,0 12px 48px #002c6d1a;
  }

  .btn.btn-icon {
    width: 51px;
    height: 51px;
    display: flex;
    padding: 0;
    line-height: 1;
    align-items: center;
    justify-content: center;
    border: none;
  }

  button:hover, [type=button]:hover, [type=reset]:hover, [type=submit]:hover {
    cursor: pointer;
  }
  
  [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
  }
  
  button, select {
    text-transform: none;
  }

  svg, video {
    vertical-align: middle;
  }
  
  .dropdown-menu.show {
    display: block;
  }

  .company-dashboard-dropdown {
    background: #FFFFFF;
    border: 1px solid #E9EAED;
    box-sizing: border-box;
    box-shadow: 0 12px 32px #18191c14;
    border-radius: 6px;
    padding: 12px 0;
    width: 216px;
  }

  .dropdown-menu {
    position: absolute;
    color: #474C54;
    display: none;
    z-index: 1000;
    min-width: 10rem;
    font-size: 1rem;
    text-align: left;
    list-style: none;
  }

  .company-dashboard-dropdown .dropdown-item {
    color: #5E6670;
    font-size: 14px;
    padding: 8px 15px!important;
    transition: all .4s;
  }

  .company-dashboard-dropdown .dropdown-item:hover{
    background-color: #EEF5FC;
    color: #0A65CC;
  }
  
  .dropdown-menu-end {
    --bs-position: end;
  }

  .dropdown-item {
    display: block;
    width: 100%;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }

  .company-dashboard-dropdown .dropdown-item svg {
    margin-right: 10px;
  }
  
  button, input, optgroup, select, textarea {
    margin: 0;
  }
  [type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
  }

  audio, canvas, iframe, img, svg, video {
    vertical-align: middle;
  }
  
  .copyFooter {
    background: #ffffff;
    box-shadow: inset 0 1px #edeff5;
    padding: 20px 0;
  }

  .text-gray-500 {
    color: #767f8c!important;
  }

  .body-font-4 {
    font-size: 14px;
    line-height: 22px;
  }

  /* .text-center {
    text-align: center!important;
  } */