

.content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.content h2 {
    margin-bottom: 20px;
    color: #343a40;
}

.content p {
    margin-bottom: 10px;
    color: #495057;
}

.dashboard-footer {
    margin-top: 20px;
    padding: 10px 0;
    border-top: 1px solid #dee2e6;
    background-color: #f8f9fa;
}
