
  .chatContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    height: calc(100vh - 150px); /* Adjust height to fit the screen */
  }
  
  .chatBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
    position: relative; /* Added to position the messages correctly */
  }
  
  .message {
    padding: 10px;
    margin: 5px 0;
    border-radius: 10px;
    max-width: 70%;
    border: 1px solid lightgrey;
    position: relative;
    opacity: 1 !important; /* Ensure opacity stays at 1 */
    transition: none !important; /* Remove any transition */
    background-color: #e5f7ff; /* Added background color for better visibility */
  }
  
  .userMessage {
    background-color: #007bff;
    color: white;
    align-self: flex-end;
    border: 1px solid lightgrey;
  }
  
  .botMessage {
    background-color: #e5e5ea;
    color: black;
    align-self: flex-start;
    border: 1px solid lightgrey;
  }
  
  .response-item {
    margin-bottom: 10px;
  }
  
  .inputBox {
    display: flex;
    margin-top: 10px;
  }
  
  .inputBox input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-right: 10px;
  }
  
  .inputBox button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .inputBox button:hover {
    background-color: #0056b3;
  }
  