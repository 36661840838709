.col-9 {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%; /* Use 70% width to better align it with "Post Job" button */
    margin-top: 20px; /* Add some margin to avoid it sticking to the top */
}

input[type="file"] {
    display: block;
    margin-bottom: 20px;
    padding: 12px;
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    border-radius: 6px;
    width: 100%;
    max-width: 600px; /* Keep input field reasonable within content */
    font-size: 16px;
}

button {
    background-color: #007bff;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    max-width: 200px;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}

.search-results-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.search-results-table th,
.search-results-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
}

.search-results-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.search-results-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.result-count {
    margin-top: 15px;
    font-weight: bold;
}
