.social-links-section {
  margin-top: 20px;
}

.social-link-row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.social-media-select {
  position: relative;
  flex: 1;
  
  /* margin-right: 5px; */
}

/* styles.css */

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-spn {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  margin-left: 20px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.social-media-select {
  flex: 1;
  /* width: 100%; */
  padding-right: 30px; 
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none; 
  background: url("../Image/CaretDown.svg") no-repeat right center;
  background-size: 20px; 
  border-right: none;
  
}
.social-media-select::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 1px;
  background-color: #ccc;
  z-index: 1; 
}

.social-media-input {
  width: 700px;
  flex: 2;
  border: 1px solid #ccc; /* Add a light grey border */
  border-radius: 4px;
  border-left: none;
  z-index: 2;
}
.custom-width {
  width: 100% !important;
}

.btns.disabled {
  cursor: not-allowed;
  opacity: 0.6; /* Optionally reduce opacity for a visual effect */
}

.social-input-container {
display: flex;
border: 1px solid #ccc; /* Add a light grey border */
border-radius: 4px;
overflow: hidden; /* Hide the border between select and input */
}

.btn {
  /* background: none;
  border: none; */
  cursor: pointer;
}

.btn-remove {
  margin-left: 10px;
}

.btn-add {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  color: #18191C;
  background-color: #ccc;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 20px;  */
}
.btn-add:hover{
  background-color: #c5c3c3;
}
.cross-btn{
  /* width: 10%; */
  /* margin-left: 30px; */
  padding-left: 25px;
}


 .resume-upload-container {
  display: flex;
  align-items: center;
}

.resume-upload-label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.upload-box {
  border: 2px dashed #ccc;
  padding: 14px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.uploaded-file {
  width:"293px";
  background-color: #f0f0f0;
  padding: 16px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.file-name {
  margin-right: 10px;
}
.btns{
  border: none;
  border-radius: 4px;
  padding: 16px 32px;
  font-weight: 500;
  background: #0A65CC;
}



@media (max-width: 767px) {
  .resume-upload-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .cross-btn img{
    padding-left: -30px;
    /* margin-right: 50px; */
  
  }
}

.border-red {
  border: 2px solid red;
}

input[type="number"]{
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-outer-spin-button,
 input[type="number"]::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
 }