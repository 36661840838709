.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  z-index: 999;
}

.mobile-menu {
  width: 100%;
  height: 300px;
  display: flex;
  background: #fff;
  border-radius: 5px;
  padding: 10px 20px 20px 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  flex-direction: column;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 40px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  color: black;
  /* border-radius: 6px; */
 

}

.beta{
  
    font-size: 12px;
    color:#5E6670!important;
    border: 1px solid gray;
    margin: 7px 0px 0px 10px;
    border-radius: 5px;
    padding: 0px 3px;
  
}

.menu-options{
  list-style: none;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  color: #0A65CC;
  font-weight: 400;
  font-size: 18px;
  /* border: 1px solid red; */
  /* padding-left: -20px; */
}



.cross-logo{
  display: flex;
 border-bottom: 2px solid rgb(222, 221, 221);
  padding: 10px 0px;
}
 .cross-logo a{
  padding-left: 10px;
 }
.menu-options li{
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 15px 0px;
  color: rgb(92, 91, 91);
  border-bottom: 1px solid lightgray;
  cursor: pointer;
}

.menu-options li:hover{
 color: #0A65CC;
 border-bottom: 1px solid #0A65CC;
 cursor: pointer;
}

/* section-2 */

section.banner-section{
  background-color: rgba(241,242,244,.6);
  border-bottom: 3px solid rgba(241,242,244,.6);
  border-top: 3px solid rgba(241,242,244,.6);
}

.banner-section{
  padding-top: 70px !important;
  margin-top: -20px;
}

.banner-section{
  padding-bottom: 10px;
}
.position-parent, .has-title-shape {
  position: relative;
  z-index: 1;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}

@media (min-width: 1200px){
.col-xl-6 {
  flex: 0 0 auto;
  width: 50%;
}
}

.align-items-center {
  align-items: center!important;
}
.d-flex {
  display: flex!important;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.banner-mockup {
  animation: float 3s ease-in-out infinite;
}

.banner-mockup {
  text-align: right;
}
@media (min-width: 1200px){
.d-xl-block {
  display: block!important;
}
}

.w-100 {
  width: 100%!important;
}

.rt-spacer-100 {
  height: 10px;
}

.ft-wt-5 {
  font-weight: 500!important;
}
h4, .text-h4 {
  font-size: 32px;
  line-height: 40px;
}
.text-center {
  text-align: center!important;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
label {
  display: inline-block;
}

.rt-spacer-50 {
  height: 50px;
}
.midwhy{
  display: flex;
  flex-direction:column ;
  justify-content: flex-end;
  align-items: center;
  /* padding-left: 65px; */
 
}


.sec-3-part-2{
  display: flex;
  justify-content:space-between;
  /* align-items: center;
  */
  margin: 0px 50px;
  
 
}
.space{
  height: 10px;
}
.body-font-2{
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}
.text-gray-400 {
  color: #5E6670!important;
}
.body-font-4 {
  font-size: 10px;
  line-height: 22px;
}
.read-more{
  color: #0A65CC;
  font-weight: 500;
  font-size: 16px;
}
.sec3-div{
  padding-left: 40px;
}
/* .working-process-2{
  background-color: #F1F2F4;
} */

.tw-bg-\[\#F1F2F4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 242 244 / var(--tw-bg-opacity));
}

.rt-spacer-100 {
  height: 100px;
}

.ft-wt-5 {
  font-weight: 500!important;
}
h4, .text-h4 {
  font-size: 32px;
  line-height: 40px;
}
.text-center {
  text-align: center!important;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

/* .text-primary-500 {
  color:#0A65CC!important;
} */
.position-parent, .has-title-shape {
  position: relative;
  z-index: 1;
}
.has-title-shape img {
  position: absolute;
  left: -8px;
  bottom: 0px;
  z-index: -1;
}
img {
  max-width: 100%;
}
audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

label {
  display: inline-block;
}

.rt-mb-24 {
  margin-bottom: 24px;
}

.position-relative {
  position: relative!important;
}
.hoverEffect:hover{
  /* cursor: pointer; */
  background-color: #fff;
  border-radius: 10px;
}
@media (min-width: 992px){
.col-lg-3 {
  flex: 0 0 auto;
  width: 25%;
}
}

.has-arrow {
  position: absolute;
  right: -33%;
  z-index: 5;
  top: 0;
}

img {
  max-width: 100%;
}

.rt-single-icon-box.working-progress {
  background: transparent;
  border-radius: 12px;
  padding: 24px;
  z-index: 0;
}

.rt-single-icon-box.icon-center {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.rt-single-icon-box {
  display: flex;
  transition: all .24s ease-in-out;
}
.rt-single-icon-box .icon-thumb {
  flex-shrink: 0!important;
}

.rt-mb-24 {
  margin-bottom: 24px;
}

.rt-single-icon-box.working-progress .icon-72 {
  color:#0A65CC;
  background-color: #fff;
  border-radius: 50%;
  z-index: 0;
}

.icon-72 {
  width: 72px;
  height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all .24s ease-in-out;
  font-size: 40px;
}

[class^=ph-], [class*=" ph-"] {
  display: inline-flex;
}

.rt-single-icon-box .iconbox-content {
  flex-grow: 1!important;
}

.rt-mb-12 {
  margin-bottom: 12px;
}
.body-font-2 {
  font-size: 18px;
  line-height: 28px;
}

.text-gray-400 {
  color: #9199a3!important;
}
.body-font-4 {
  font-size: 14px;
  line-height: 22px;
}
.has-arrow.middle {
  top: 33%;
  transform: translateY(-33%);
}

.has-arrow {
  position: absolute;
  right: -33%;
  z-index: 5;
}

footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: black;
  padding: 10px 0;
}

footer div {
  display: flex; /* Display divs in a row */
  align-items: center;
  margin: 0 10px; /* Add margin for spacing */
  color: white; /* Text color (adjust as needed) */
}

.name{
  color: #fff;
  font-size: 26px;
font-weight: 600;
margin-left: 10px;
}

.up{
background-color: #0A65CC;
color: #fff;
}

.how-bg{
  background-color: rgba(241,242,244,.6);
  border-bottom: 3px solid rgba(241,242,244,.6);
  border-top: 3px solid rgba(241,242,244,.6);
}

/* For screens wider than the small (sm) breakpoint (default screens) */
@media (min-width: 869px) {
  .hideimg {
    display: none; /* Hide the first image on default screens */
  }
  .showimg {
    display: block; 
  }

}

/* For screens smaller than or equal to the small (sm) breakpoint (mobile screens) */
@media (max-width: 868px) {
  .hideimg {
    display: block; 
  }
  .showimg {
    display: none; /* Hide the last image on mobile screens */
  }
}


@media screen and (max-width: 869px){
  .section-3{
      /* width: 100%; */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .sec-3-part-2{
  
      display: flex;
      flex-direction: column;
      justify-content:center;
      align-items: center;
     
  }
  .midwhy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    
  }
  .hideimg {
    display: block; /* Show the first image on mobile screens */
  }
  .showimg {
    display: none; /* Hide the last image on mobile screens */
  }
  .sec3-div{
      width: 100%;
     /* margin-right: 500px !important; */
     /* padding-left: -1000px; */
  }

  footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: black;
      padding: 10px 0;
    }
    
    footer div {
      display: flex; /* Display divs in a row */
      align-items: center;
      justify-content: center;
      margin: 0 10px; /* Add margin for spacing */
      color: white; /* Text color (adjust as needed) */
      text-align: left;
      margin-bottom: 5px;
    }
  
    .name{
      color: #fff;
      font-size: 26px;
    font-weight: 600;
    margin-left: 10px;
    }
    .imageWidth {
      width: 400px; /* Set the desired width for the images */
      height: 300px; /* Set the desired height for the images */
      object-fit: cover; /* This property ensures the image maintains its aspect ratio */
      /* border: 1px solid red; */
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .sec-div-working-process{
      /* border: 1px solid black; */
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right:45px ;

    }
    .midwhy .body-font-2,
    .midwhy .body-font-4 {
      display: flex;
      /* justify-content: space-around;
      align-items: center; */
      text-align: left;
      /* border: 1px solid black; */
      padding-left: 40px;
    }
}

@media (min-width: 870px) {
  .midwhy {
    /* Add styles specific to large screens here */
    /* border: 1px solid red; */
    margin-left: 85px;
  }

  /* .sec-div-working-process {
   
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 45px;
  } */

  /* .midwhy .body-font-2,
  .midwhy .body-font-4 {
    
    display: flex;
    text-align: left;
    padding-left: 30px;
  } */
}
.ft-wt-5 {
  font-weight: 500!important;
}

@media (max-width: 1199.98px){
h4, .text-h4 {
  font-size: 40px;
  line-height: 48px;
}
}

.text-center {
text-align: center!important;
}

.col-12 {
flex: 0 0 auto;
width: 100%;
}

.rt-mb-24 {
margin-bottom: 24px;
}

@media (min-width: 992px){
.col-lg-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
}

.rt-single-icon-box.working-progress {
background: transparent;
border-radius: 12px;
padding: 24px;
z-index: 0;
}

.rt-single-icon-box.icon-center {
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
}

.rt-single-icon-box {
display: flex;
/* align-items: center; */
transition: all .24s ease-in-out;
}

.rt-single-icon-box .icon-thumb {
flex-shrink: 0!important;
}

.rt-mb-24 {
margin-bottom: 24px;
}

.rt-single-icon-box.working-progress .icon-72 {
color: var(--primary-500);
background-color: #fff;
border-radius: 50%;
z-index: 0;
}

.icon-72 {
width: 72px;
height: 72px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
/* border-radius: 4px; */
transition: all .24s ease-in-out;
/* background-color: transparent; */
font-size: 40px;
}

[class*=" ph-"] {
display: inline-flex;
}

.rt-single-icon-box .iconbox-content {
flex-grow: 1!important;
}

.rt-mb-12 {
margin-bottom: 12px;
}

.body-font-2 {
font-size: 18px;
line-height: 28px;
}

.text-gray-400 {
color: #474849!important;
}
.body-font-4 {
font-size: 14px;
line-height: 22px;
}

.tw-text-center {
text-align: center;
}

.rt-mb-24 {
margin-bottom: 24px;
}

.col-12 {
flex: 0 0 auto;
width: 100%;
}

.body-font-3 {
font-size: 16px;
line-height: 24px;
}

p {
margin-top: 0;
margin-bottom: 1rem;
}

.contact-us{
color: #0851a4;
text-decoration: none;
transition: all .24s ease-in-out;
}