.job-cards-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  }
  
  .job-card-wrapper {
    width: 100%;
  }
  
  .job-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .job-card h3 {
    margin: 0 0 10px;
    font-size: 1.2em;
    color: #333;
  }
  
  .job-card p {
    margin: 5px 0;
    color: #666;
  }
  
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  
  .view-button,
  .apply-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .view-button {
    background-color: #007bff;
    color: #fff;
  }
  
  .apply-button {
    background-color: #28a745;
    color: #fff;
  }
  