@media (max-width: 768px) {
    .scrollable-container {
      overflow-x: auto;
      white-space: nowrap; /* Prevent line breaks */
    }
    .sidebarDiv{
        display: none;
    }
    .mobile-screen{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
  }
  
  @media (min-width: 769px){
  .hamburgIcon{
    display: none;
  }
  }