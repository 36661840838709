.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    width: 100%;
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
    margin-right: auto;
    margin-left: auto;
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5);
}

/* .row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
} */
@media (max-width: 991.98px){
.dashboard-right {
    display: block;
    padding-top: 30px;
    padding-bottom: 30px;
}
}
.custom-breadcrumb p {
    margin-bottom: 6px!important;
}
.custom-breadcrumb p .inactive {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5E6670;
}

.custom-breadcrumb p .iactive {
    color: #0A65CC;
}

.application-wrapper-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.application-wrapper-top .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #18191C;
    margin-bottom: 0!important;
}
.application-wrapper-top .btn {
    font-weight: 500!important;
    font-size: 14px!important;
    line-height: 20px!important;
    padding: 6px 24px!important;
}
button:not(:disabled) {
    cursor: pointer;
}

.btn-primary {
    background: #0A65CC;
    border-color: #0A65CC;
    color: #fff;
}
.back-buttn{
    width: auto;
    border-radius: 10%;
}

.application-wrapper-bottom {
    display: flex;
    gap: 24px;
    overflow: hidden;
    overflow-x: scroll;
}