.login-form-container {
  padding: 20px 5px 20px 35px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
 
}

.form-header {
  color: rgba(128, 128, 128, 0.729);
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: black;
}

.input {
  width: 93%;
  padding: 5px 10px;
  border: 1px solid gray;
  border-radius: 4px;
}

.error-message {
  color: red;
  margin-top: 5px;
}

.login-button {
  color: white;
  background-color: rgb(9, 117, 240);
  padding: 6px 40px;
  display: block;
  margin: auto;
  border: none;
  border-radius: 5px;
  margin-right: 90px;
  margin-top: 14px;
  font-size: 15px;
}

@media screen and (max-width: 450px) {
  .login-form-container {
    margin-left: 30px;
    margin-right: 30px;
  }

  .input {
    width: 93%;
  }

  .login-button {
    padding: 9px 70px;
    margin-right: 45px;
  }
}
