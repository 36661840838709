.help-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: white;
    border-radius: 50%;
    padding: 5px 10px;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  .tooltip-container {
    position: relative;
  }

  .custom-tooltip {
    position: absolute;
    top: -120px; 
    right: 20px; /* Adjust this value for the desired right margin */
    background-color: black;
    color: white;
    border: 1px solid #ccc;
    padding: 10px;
    max-width: 300px;
    text-align: left;
    /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); */
  }
  
  
  
  .tooltip-content {
    background-color: black;
    color: white;
    border: 1px solid #ccc;
    padding: 10px;
    max-width: 300px;
    text-align: left;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }

  .tooltip-content > p {
    color: white;
   
  }
  
  /* .close-btn {
    display: block;
    margin-top: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  } */

  .tooltip-inner {
    max-width: 100%;
    text-align: center;
  }
  
  .tooltip.show {
    opacity: 1 !important;
    visibility: visible !important;
    transform: translate(-50%, -100%) !important; /* Adjust this if needed */
    transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
  }