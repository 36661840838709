.checkbox-input{
  padding: 3px;
  margin: 5px;
 border: 1px solid gray;
}
.form-check-label{
   /* border: 1px solid red; */
   margin-right: 13px;
   /* font-weight: normal; */
}
.qstnsave{
  border: 1px solid red;
}

.status {
  display: flex;
  flex-direction: row-reverse;
 }

.label-txt label:nth-child(1){
 font-size: .875rem;
 line-height: 1.25rem;
 font-family: sans-serif;
}
.label-checks{
 font-size: 14px;
 line-height: 1.25rem;
 font-family: sans-serif;
}

.tw-text-\[\#18191C\] {
 --tw-text-opacity: 1;
 color: rgb(24 25 28 / var(--tw-text-opacity));
}

.tw-font-medium {
 font-weight: 500;
}
.tw-text-2xl {
 font-size: 1.5rem;
 line-height: 2rem;
}
.tw-mb-8 {
 margin-bottom: 2rem;
}

.text-danger {
 color: #dc3545!important;
}

/* button */

tw-overflow-hidden {
 overflow: hidden;
}
.tw-w-full {
 width: 100%;
}
.rt-mb-15 {
 margin-bottom: 15px;
}

[type=submit]:not(:disabled), button:not(:disabled) {
 cursor: pointer;
}

.postjobbtn{
  background-color: #0A65CC;
  color: #F8F9FA;
  font-weight: 600;
  padding: 12px 24px 13px;
  border: none;
  border-radius: 4px;
  
  font-size: 16px;
  font-family: sans-serif;
}
.rt-mr-10 {
 margin-right: 10px!important;
}
.btn .button-content-wrapper {
 display: flex;
 justify-content: center;
 align-items: center;
 line-height: 100%;
}

.btn .button-content-wrapper .button-icon.align-icon-right {
 order: 15;
 margin-left: 8px;
 margin-right: 0;
}

.btn .button-content-wrapper .button-text {
 flex-grow: 1;
 order: 10;
}
.post-buttn{
  width: auto;
  border: 1px solid red;
}

.cursor-none {
  cursor:not-allowed;
}

/* .locat{
  margin-bottom: -1px;
} */


.location-suggestions-overlay {
  position: absolute;
  width: calc(100% - 525px); /* Adjust for border width */
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  z-index: 1000;
  margin-top: -35px;
}

.radiohour{
  margin-top: 35px;
}

.company{
  width: 31.5%;
}

.dolr{
  margin-top: 7px;
  margin-right: -10px; 
  z-index: 1;
}



/* .compensation{
  width: 33;
} */

.form-group.text-start.label-txt {
  cursor: pointer;
  position: relative;
}

.location-suggestions-overlay ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.placeholder-gray::placeholder {
 color: rgb(165, 163, 163); 
}

.btn{
  width: auto;
}
.genbtn{
  width: auto;
  height: auto;

}

 @media (max-width: 576px) {
   .status {
    display: flex;
    justify-content: center;
   }
   .post-buttn{
    width: auto;
    border: 1px solid red;
  }
 }

  .form-control{
   border: 1px solid gray;
  }
 .radio-input{
   padding: 3px;
  margin: 5px;
 border: 1px solid gray;
 }
 
 input:focus,
textarea:focus,
select:focus {
   outline: none !important;
   box-shadow: none !important;
   border: 1px solid gray;
 }
 input:focus-visible {
 outline: none;
}

.main-search{
 display: flex;
 flex-direction: column;
 font-size: .875rem;
 line-height: 1.25rem;
 font-family: sans-serif;
}

 
.lineStyle{
 display: flex;
 justify-content: space-between;
 font-size: .875rem;
 line-height: 1.25rem;
 font-family: sans-serif;
}

/* .tw-flex a{
 text-decoration: none;
 color: #0A65CC;
} */

/* .tw-flex a:hover{
 cursor: pointer;
 color: #0A65CC;
} */

.checks-text{
 display: flex;
 justify-content: space-between;
 font-size: .875rem;
 line-height: 1.25rem;
 font-family: sans-serif;
 gap: 20px;
 
}

.checks-text label{
 color: grey;
}

.search-results-dropdown {
 position: absolute;
 background-color: white;
 border: 1px solid #ccc;
 max-height: 150px;
 overflow-y: auto;
 width: 94%;
 z-index: 100;
 border-radius: 5px;
 box-shadow: 0 4px 6px rgba(226, 225, 225, 0.1);
}

.search-result {
 padding: 10px;
 display: flex;
 justify-content: flex-start;
 align-items: center;
 color:rgb(93, 91, 91);
 font-weight: 500;
 font-size: 17px;
 font-family: sans-serif;

}
.search-result:hover{
 background-color: #498ad4;
 color: #fff;
 cursor: pointer;
}

.search-result button {
 margin-left: 10px;
}

.ulstyle{
 list-style: none;
 margin-left: -5px;
}
.flex-grow-1{
  /* border: 1px solid red; */
  margin-left: -25px;
 }

 .border-red {
  border: 2px solid red;
}