/* EditPopup.css */

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    z-index: 9999; /* Ensure the popup appears on top of other elements */
  }
  
  .popup-content {
    /* Add your desired styles for the content */
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-height: 80vh; /* Limit the height to fit within the viewport */
    overflow-y: auto;
  }

  .questions-container {
    margin-top: 8px; /* Add margin at the top */
    display: flex;
    flex-direction: column;
  }

  .question-input {
    margin-bottom: 8px; /* Add margin at the bottom */
  }

  .form-group .job-description {
    max-height: 300px;
    overflow-y: auto;
  }
  /* .message {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #4770bb; 
    color: white; 
    text-align: center;
    padding: 8px;
    z-index: 9999; 
  }
  
  
  .message.hidden {
    display: none;
  } */