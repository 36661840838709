.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupContent {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 80%;
  text-align: left;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  cursor: pointer;
}

.close:hover {
  color: red;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.view-resume-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-resume-btn:hover {
  background-color: #0056b3;
}

.archived-text {
  color: red; 
}

.message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #007bff; 
  color: #fff;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999; 
  animation: fadeOut 3s forwards; 
}

/* Animation keyframes */
@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
