.container {
    margin-top:20px;
  }
  
  .row {
    display: flex;
  }
  
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  .job-list {
    /* background-color: #f4f4f4; */
    padding: 20px;
    margin-top: -18px;
  }

  .job-list div{
    background-color: #f4f4f4;
    border-radius: 12px;

  }
  
  .job-list h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .job-list ul {
    list-style: none;
    padding: 0;
  }

  .search-container {
    display: flex;
    align-items: center;
    padding: 0px 100px;
    /* border: 1px solid red; */
  }
  
  .search-input {
    flex-grow: 1;
    margin-right: 10px;
    padding: 8px 40px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 8px rgba(24, 25, 28, 0.16);
  }
  
  .search-button {
    padding: 8px 16px;
    font-size: 16px;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .filters-container {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    /* background-color: lightblue; */
    padding: 10px;
  }
  
  .filter {
  position: relative;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px 0px 12px; 
  border: none !important; 
  
  }

  .filter select{
    background-color: rgba(231, 240, 250, 1);
    padding: 10px 25px 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  .filter option {
      padding: 10px 20px;
  }

  select::-ms-expand {
    display: none;
  }

  select {
    border: none;
    /* padding: 8px; 
    -webkit-appearance: none;
    -moz-appearance: none; 
     appearance: none;
    position: relative; */
}

select {
  -webkit-appearance: none; /* Chrome, Safari, Opera */
  -moz-appearance: none; /* Firefox */
  appearance: none; /* Standard syntax */
  background-image: url('https://icons8.com/icon/60662/expand-arrow'); /* Replace 'custom-dropdown-arrow.png' with the path to your custom image */
  background-repeat: no-repeat;
  background-position: right center; 
  padding-right: 20px; 
}

.filter img {
  /* background-position: center center;;  */
  transform: translateY(-50%) translateX(100%);
}

.caret-icon {
  position: absolute;
  top: 40%; /* Position caret vertically in the middle */
  right: 35px; /* Position caret to the right */
  transform: translateY(-50%);
}

  
  .filter label {
    margin-right: 5px;
  }

  /* Style for search icon */
.search-icon {
  width: 20px; 
  height: 20px; 
  margin-right: -25px; 
  z-index: 1;
}

/* Style for location icon */
.location-icon {
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  margin-right: -25px; /* Add margin to separate the icon from the input field */
  z-index: 1;
}

/* Add this CSS to your jobRecommandation.css file */
/* Add this CSS to your jobRecommandation.css file */


.location-suggestions {
  
  position:absolute;
  z-index: 999;
  /* top: calc(100% + 5px);  */
 
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  width: calc(50% - 282px);
  /* border: 1px solid red; */
  margin-left: 514px;
  top: calc(10% + 5px); /* Position below the input */
  
}

.location-suggestions li {
  list-style-type: none;
  padding: 8px 16px;
  cursor: pointer;
}

.location-suggestions li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

  
.job-list .jobBox {
    cursor: pointer;
    padding: 20px;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}
  
  .jobBox.selected {
    border: 2px solid #2557a7; /* Blue border for selected job */
  }
  
  .jobBox:hover {
    background-color: #f0f0f0;
  }
  
  .job-list .jobBox:hover {
    background-color: #e6e6e6;
  }
  
  .job-details {
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .job-list .companyName{
      /* font-family: 'Noto Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
      color: rgb(45, 45, 45); */
      font-size: 1.25rem;
      line-height: 1.5rem;
      margin: 0 0 0.5rem 0;
      font-weight: 600;
  }
  .job-details h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .job-details h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .job-details p {
    margin-bottom: 10px;
  }
  
  .dashboard-footer {
    margin-top: 20px;
    padding: 10px 0;
    background-color: #f4f4f4;
  }
  .applyButton{
      background-color: #2557a7;
      border: .125rem solid #2557a7;
      font-size: 1rem;
      color: rgb(255, 255, 255);
      padding: .5625rem 1rem;
      min-height: 2.625rem;
      font-weight: 700;
      border-radius: 0.5rem;
      box-sizing: border-box;
      background: none rgb(37, 87, 167);
      appearance: none;
      text-align: left;
      text-decoration: none;
      border: 1px solid rgb(37, 87, 167);
      cursor: pointer;
      user-select: none;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      position: relative;
      margin: 0px;
  }
  .svg-1{
   width: 20px;   
  }
  
  .seeMore {
    color: #007bff; /* Blue color for the link */
    cursor: pointer;
  }