.flash-message {
    position: fixed;  /* Position the element relative to the viewport */
    top: 0;           /* Position it at the top of the viewport */
    left: 50%;        /* Center it horizontally */
    transform: translateX(-50%); /* Shift it left by half its width to center it */
    width: auto;      /* Auto width based on content */
    max-width: 90%;   /* Max width to ensure it doesn't span too wide */
    z-index: 1050;    /* High z-index to float above other content */
    /* background-color: #5eac5e;  */
    /* color: #721c24;    */
    padding: 10px 20px; /* Padding inside the flash message */
    border-radius: 5px; 
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    display: block;   /* Ensure it's always a block */
}