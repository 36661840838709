/* Style for the select element */
.statusSelect {
    padding: 5px 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
    cursor: pointer; /* Change cursor to pointer on hover */
  }
  
  /* Style for the select element when hovering */
  .statusSelect {
    background-color: #E7F0FA;
    color: #0A65CC;
    border: none;
  }
  
  /* Style for the options within the select element */
  .statusSelect option {
    background-color: white;
    color: black;
  }
  
  /* Style for the options within the select element when hovering */
  .statusSelect option:hover {
    background-color: green;
    color: red;
  }
  